import Model from '@/dc-it/models/Model'
import Field from '../types/Field'

export default class TagGroup extends Model {
    endpoint = 'tag_groups';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre en español'),
      new Field('name_en', 'Nombre en inglés'),
    ];

    clone = () => TagGroup;
}
